import "./style.scss";

import { isDefined } from "@clipboard-health/util-ts";
import { IonList, IonModal } from "@ionic/react";
import { CircularProgress, Stack } from "@mui/material";
import { isHCPInstantPayAllowed } from "@src/app/dayView/api";
import { UpdateShiftListFn } from "@src/app/dayView/custom-hooks/interfaces";
import { ShiftPaginationLoader } from "@src/app/dayView/shiftItem/components/ShiftPagination";
import { useDefinedAgent } from "@src/app/store/helperHooks";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";
import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import { Shift } from "@src/appV2/Shifts/Shift/types";
import { UrgentShift } from "@src/appV2/Shifts/UrgentShifts/api/useUrgentShifts";
import { SEARCH_MODE } from "@src/constants";
import { LegacyFacility, LegacyShift } from "@src/lib/interface/src";
import { FetchNextPageOptions } from "@tanstack/react-query";
import { FC, useEffect, useMemo, useState } from "react";

import { instantPayStatus } from "../../dayView/api";
import { FacilityDetails } from "../../dayView/facilityDetails";
import { ShiftItem } from "../../dayView/shiftItem";

interface UrgentShiftItemsProps {
  shifts: UrgentShift[];
  updateShiftList: UpdateShiftListFn;
  refreshShifts: () => void;
  searchMode: SEARCH_MODE;
  shiftBookabilityById: Record<string, BookabilityStatusItem>;
  bookabilityCheckQueryResult: {
    isLoadingShiftBookability: boolean;
    isFetchingShiftBookabilityForNextPage: boolean;
    hasNextOpenShiftPage?: boolean;
    shiftsToRenderCount?: number;
    fetchNextPageBookabilityStatus: ((options?: FetchNextPageOptions) => void) | null;
  };
}

export const UrgentShiftItems: FC<UrgentShiftItemsProps> = ({
  shifts,
  updateShiftList,
  refreshShifts,
  searchMode,
  shiftBookabilityById,
  bookabilityCheckQueryResult,
}) => {
  const {
    isLoadingShiftBookability,
    isFetchingShiftBookabilityForNextPage,
    fetchNextPageBookabilityStatus,
    hasNextOpenShiftPage,
    shiftsToRenderCount,
  } = bookabilityCheckQueryResult;
  const [facilityDetails, setFacilityDetails] = useState<LegacyFacility | null>();
  const [shiftDetails, setShiftDetails] = useState<Shift | null>();
  const [isHcpInstantPayEnabled, setIsHcpInstantPayEnabled] = useState(false);

  const agent = useDefinedAgent();

  const onClickOnItemFacilityDetails = (shift: Shift) => {
    setFacilityDetails(shift.facility as LegacyFacility);
    setShiftDetails(shift);
  };

  const closeFacilityDetails = () => {
    refreshShifts();
    setFacilityDetails(null);
    setShiftDetails(null);
  };

  const availableShiftIds = useMemo(() => {
    return shifts ? shifts.map((shift) => shift.shiftId || shift._id!) : [];
  }, [shifts]);

  const fetchHcpInstantPayStatus = async () => {
    const status = await instantPayStatus();
    setIsHcpInstantPayEnabled(isHCPInstantPayAllowed(status));
  };

  useEffect(() => {
    fetchHcpInstantPayStatus();
  }, []);

  return (
    <>
      <IonList lines="none">
        {shifts?.map?.((shift, shiftIndex) => {
          const shiftBookability =
            shiftBookabilityById[shift.shiftId ?? ""]?.attributes.bookability;

          if (isDefined(shiftsToRenderCount) && shiftIndex >= shiftsToRenderCount) {
            return null;
          }

          return (
            <>
              {hasNextOpenShiftPage &&
                isDefined(fetchNextPageBookabilityStatus) &&
                !isFetchingShiftBookabilityForNextPage &&
                shiftIndex + 1 === shiftsToRenderCount && (
                  <ShiftPaginationLoader
                    onLoadNextPage={() => {
                      logEvent(APP_V2_USER_EVENTS.VIEWED_NEXT_OPEN_SHIFT_PAGE, {
                        searchMode,
                        shiftsToRenderCount,
                      });

                      fetchNextPageBookabilityStatus();
                    }}
                  />
                )}
              <ShiftItem
                updateShiftList={updateShiftList}
                key={shift.shiftId}
                shift={shift as LegacyShift}
                shiftName={shift.name as ShiftPeriod}
                isInterested={!!shift.interested?.find((agentId) => agentId === agent?.userId)}
                onFacilityDetailsClick={(shift: LegacyShift) => {
                  onClickOnItemFacilityDetails(shift as Shift);
                }}
                isHcpInstantPayEnabled={isHcpInstantPayEnabled}
                isUrgentShift
                searchMode={searchMode}
                availableShiftIds={availableShiftIds}
                shiftBookability={shiftBookability}
                isShiftBookabilityLoading={
                  isLoadingShiftBookability || isFetchingShiftBookabilityForNextPage
                }
              />
            </>
          );
        })}
        {isFetchingShiftBookabilityForNextPage && (
          <Stack
            sx={{
              alignItems: "center",
              paddingY: 1,
            }}
          >
            <CircularProgress />
          </Stack>
        )}
      </IonList>

      <IonModal isOpen={!!facilityDetails} onDidDismiss={closeFacilityDetails}>
        {facilityDetails && shiftDetails && (
          <FacilityDetails
            onClose={closeFacilityDetails}
            facility={facilityDetails}
            shift={shiftDetails as LegacyShift}
          />
        )}
      </IonModal>
    </>
  );
};
