import { Preferences } from "@capacitor/preferences";
import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { useSession } from "@src/app/store/helperHooks";
import { isAndroidPlatform } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { getCurrentLocationStatus, isOsWithoutAlwaysAllowOption } from "@src/appV2/Location";
import { UrgentShiftLocalStorage } from "@src/appV2/Location/constant";
import { useNextTwoDaysShifts } from "@src/appV2/Shifts/MyShifts/api/useNextTwoDaysShifts";
import { Shift } from "@src/appV2/Shifts/Shift/types";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import { AlwaysAllowLocationDialog } from "./AlwaysAllowLocationDialog/AlwaysAllowLocationDialog";
import { updateAgentData } from "./api";
import {
  checkIsFullLocationPermissionRequired,
  getPreviousLocationPermissionStatus,
  handleException,
  requestFullLocationAccess,
} from "./utils";
import { updateAgent } from "../../store/session";

const UrgentShiftPermissionContainer: FC<{}> = () => {
  const { agent } = useSession();

  const permissionModalState = useModalState();

  const { data: isOsWithoutAlwaysAllow = false } = useQuery({
    queryKey: ["isOsWithoutAlwaysAllow"],
    queryFn: async () => {
      return await isOsWithoutAlwaysAllowOption();
    },
    enabled: permissionModalState.modalIsOpen,
  });

  const { data: nextTwoDaysShiftsData, isError: isNextTwoDaysShiftsError } = useNextTwoDaysShifts(
    agent?.tmz ?? "",
    {
      enabled: isDefined(agent?.tmz),
    }
  );

  const dispatch = useDispatch();

  const handleUpdateAgent = async (
    agentId: string,
    data: Record<string, unknown>,
    updateInfo: Record<string, unknown>
  ) => {
    await Preferences.set({
      key: UrgentShiftLocalStorage.LAST_LOCATION_PERMISSION_STATUS,
      value: JSON.stringify({ ...data, agentId: agent?.userId }),
    });

    await updateAgentData(agentId, updateInfo);

    dispatch(
      updateAgent({
        mobile: {
          ...agent?.mobile,
          ...data,
        },
      })
    );
  };

  const updateLocationServiceStatus = async (shiftList: Shift[], shiftListError: boolean) => {
    const data = await getCurrentLocationStatus();

    const fields = ["isLocationServicesEnabled", "locationServicesPermission", "preciseLocation"];
    const updateInfo = {};

    let dataUpdated = false;
    const previousPermission = await getPreviousLocationPermissionStatus(agent);

    fields.forEach((field) => {
      updateInfo[`mobile.${field}`] = data[field];

      dataUpdated = dataUpdated || data[field] !== previousPermission?.[field];
    });

    const requiresFullLocationPermission = await checkIsFullLocationPermissionRequired(data);

    if (dataUpdated && !isEmpty(updateInfo)) {
      handleUpdateAgent(agent?.userId ?? (previousPermission?.agentId as string), data, updateInfo);
      logEvent(APP_V2_APP_EVENTS.WORKER_UPDATED_LOCATION_SETTINGS, {
        agentId: agent?.userId,
        previousPermission,
        ...data,
      });
    }

    if (
      requiresFullLocationPermission &&
      (shiftListError ||
        shiftList.length > 0 ||
        (agent?.userId === "615e2b664a11f8016b952c52" && isAndroidPlatform()))
    ) {
      permissionModalState.openModal();
    } else {
      permissionModalState.closeModal();
    }
  };

  useEffect(() => {
    handleException(() =>
      updateLocationServiceStatus(nextTwoDaysShiftsData?.response ?? [], isNextTwoDaysShiftsError)
    )();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTwoDaysShiftsData?.response, isNextTwoDaysShiftsError]);

  return (
    <AlwaysAllowLocationDialog
      modalState={permissionModalState}
      onConfirm={() => {
        permissionModalState.closeModal();
        requestFullLocationAccess();
      }}
      isOsWithoutAlwaysAllow={isOsWithoutAlwaysAllow}
    />
  );
};

export default UrgentShiftPermissionContainer;
